import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-bootstrap';
import ReactPlayer from 'react-player';


function MediaCarousel({max_height}) {
    const [imagePaths, setImagePaths] = useState([]);

    useEffect(() => {
        async function fetchImages() {
          fetch('https://flashfotosformaturas.com.br/api/listar_imagens.php?pasta=demo').then(response => 
              response.json()
          ).then(data => {
              const images = data;
              setImagePaths(images)
            })
        }
        fetchImages();
    }, []);

    // Função para importar as imagens
    const importImages = (imageNames) => {
        return imageNames.map(imageName => {
            // Importa a imagem usando require
            const image = imageName;

            // Retorna um elemento <img> com a imagem importada
            return <img className="d-block mx-auto my-auto" key={imageName} src={image} alt={imageName} style={{ maxWidth: '100vw', maxHeight: max_height }}/>;
        });
    };

    const [isVideoPlaying, setIsVideoPlaying] = useState(false);

    const handleSlideChange = (selectedIndex) => {
        
        if (isVideoPlaying){
            setIsVideoPlaying(false);
        }
    };

    const handleSelect = (selectedIndex, e) => {
        if (isVideoPlaying){
            setIsVideoPlaying(false);
        }
    };

    return (
        <Carousel onSlide={handleSlideChange} onSelect={handleSelect} interval={isVideoPlaying ? null : 5000} fade style={{ height: (window.innerWidth/window.innerHeight) <= 1 ? 'auto' : max_height, backgroundColor: 'rgba(0, 0, 0, 0.9)', borderRadius: '8px', overflow: 'hidden'}}>
            <Carousel.Item key={"video-colacao"}>
                <ReactPlayer
                    url="https://www.youtube.com/embed/026hh6FF62o" 
                    playing={isVideoPlaying}
                    onPlay={() => {setIsVideoPlaying(true);}}
                    onPause={() => {setIsVideoPlaying(false);}}
                    onEnded={() => {setIsVideoPlaying(false);}}
                    controls={true}
                    height={max_height}
                    width='100%'
                />
            </Carousel.Item>
            {importImages(imagePaths).map((image, index) => (
                <Carousel.Item key={index}>
                    {image}
                </Carousel.Item>
            ))}
        </Carousel>
    );
}

export default MediaCarousel;
