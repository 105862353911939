import React, { useState, useEffect } from 'react';
import './App.css';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import MainNavbar from './components/navbar';
import MainPage from './components/mainPage';
import Gallery from './components/gallery';
import About from './components/about';
import WhatsAppButton from './components/whatsAppButton';
import Footer from './components/footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import './fonts/Rage.ttf';

function App() {
  const [navbarHeight, setNavbarHeight] = useState(0);
  const [overflow, setOverflow] = useState(0);

  const [contentHeight, setContentHeight] = useState(0);

  useEffect(() => {
    setOverflow(navbarHeight);
    const updateContentHeight = () => {
      const windowHeight = window.screen.height;
      setContentHeight(windowHeight-navbarHeight);
    };

    // Atualize a altura do conteúdo ao carregar a página e ao redimensionar a janela
    updateContentHeight();
    window.addEventListener('resize', updateContentHeight);

    // Remova o event listener ao desmontar o componente
    return () => {
      window.removeEventListener('resize', updateContentHeight);
    };
  }, [navbarHeight]);

  return (
  <>
    <MainNavbar onNavbarHeightChange={setNavbarHeight}/>
    <Container id="home" name="home" className="section">
      <div style={{ height: navbarHeight + 20 }}></div>
      <MainPage max_height={contentHeight - navbarHeight -60} max_width={window.screen.width}/>
    </Container>
    <Container name="gallery" className="section">
      <a id="gallery" style={{ display: "block", position: "relative", top: `-${overflow}px`, visibility: "hidden"}}></a>
      <div style={{ height: "35px" }}></div>
      <Gallery max_height={contentHeight - navbarHeight -60}/>
    </Container>
    <Container name="about" className="section">
      <a id="about" style={{ display: "block", position: "relative", top: `-${overflow}px`, visibility: "hidden"}}></a>
      <div class="dotted-line"></div>
      <div style={{ height: "35px" }}></div>
      <About max_height={contentHeight - navbarHeight -60}/>
    </Container>
    <div style={{ height: "35px" }}></div>
    <Footer/>
  <WhatsAppButton />  
  </>
  
  );
}

export default App;
