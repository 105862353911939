import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';


const About = ({max_height}) => {
    return(
        <>
            <h1 style={{ color: '#cc3300', marginBottom: '20px', textDecorationColor: '#cc3300' }}>
              Sobre a Flash Fotos Formaturas
            </h1>
            <p style={{ marginBottom: '15px', fontSize: '18px' }}>
              <b>Aqui você irá conhecer um pouco mais da nossa história!</b>
            </p>
            <p style={{ marginBottom: '30px', fontSize: '18px' }}>
            Fundada em 1994 na cidade de São José do Rio Preto/SP, a Flash Fotos Formaturas é uma empresa especializada em fotografias para formaturas. Dentro desses {new Date().getFullYear() - 1994} anos de atividades nossa empresa tem como princípios: honestidade, ética e transparência perante nossos clientes e formandos. Baseada em um misto de Tradição entre suas gerações e procurando estar sempre atualizada em seu mercado, a Flash Fotos Formaturas trabalha com equipamentos e  produtos de excelência e alto desenvolvimento tecnológico e sustentável. Atuando em diversas regiões de nosso país, tais como: São Paulo, Minas Gerais, Goias, Mato Grosso,  com Profissionais altamente qualificados que sempre estão prontos para levarem até você formando o melhor em produtos e serviços para sua grande formatura. 
            </p>
            <h2 style={{ color: '#cc3300', marginTop: '60px', marginBottom: '20px', textDecorationColor: '#cc3300' }}>
              Conheça nosso espaço e trabalho
            </h2>
            <p style={{ marginBottom: '30px', fontSize: '18px' }}>
              A Flash Fotos Formaturas vem renovando seu espaço, capacitando a equipe e investindo em melhores equipamentos e tecnologias, sembre buscando melhor te atender! Confira o vídeo demonstrativo abaixo: 
            </p>
            <ReactPlayer
                url="https://www.youtube.com/watch?v=JpfwF1DD4xc" 
                controls={true}
                height={max_height}
                width='100%'
                style={{
                    position:'static'
                }}
            />
            <p style={{ marginTop: '30px', fontSize: '50px', fontFamily:'Rage' }}>
              Flash Fotos Formaturas
            </p>
            <p style={{ marginTop: '2px', fontSize: '40px', fontFamily:'Rage' }}>
              Eternizando Momentos!
            </p>
        </>
        
    );
};

export default About;