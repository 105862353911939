import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faYoutube, faInstagram } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  return (
    <footer style={{ backgroundColor: 'black', color: 'white', padding: '20px 0', marginLeft:'15px', marginRight:'15px' }}>
      <Container>
        <Row>
          <Col md={6} sm={12}>
            <h5>Informações de Contato</h5>
            <p style={{ marginBottom: '3px', marginTop: '10px'}}>Email: contato@flashfotosformaturas.com.br</p>
            <p>Núcleo de Apoio ao Formando (NAFF): (64) 9 9662-7336</p>
          </Col>
          <Col md={6} sm={12}>
            <h5>Redes Sociais</h5>
            <p>
              <a href="https://www.youtube.com/@FlashFotosFormaturas" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faYoutube} size="2x" style={{ marginRight: '10px', color: 'white' }} />
              </a>
              <a href="https://www.instagram.com/flashfotosformaturas/" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faInstagram} size="2x" style={{ color: 'white' }} />
              </a>
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p style={{ marginTop: '20px' }}>© {new Date().getFullYear()} Flash Fotos Formaturas. Todos os direitos reservados.</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
