import React, { useState, useEffect } from 'react';
import CardCarousel from "./cardCarrousel";
import MediaCarousel from "./mediaCarousel";
import cards from './cards';
import GalleryModal from "./modal_gallery";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';

const Gallery = ({max_height}) => {
    const [isOpen, setIsOpen] = useState(false);
    return(
        <>
          <h2 style={{ color: '#cc3300', marginBottom: '20px', textDecorationColor: '#cc3300' }}>
            Confira nossas parcerias
          </h2>
          <p style={{ marginBottom: '30px', fontSize: '18px' }}>
            A Flash fotos formaturas atua em mais de <b>200</b> instituições de ensino em <b>todo o Brasil</b>. Aqui estão algumas delas:
          </p>
          <CardCarousel cards={cards}/>
          <h2 style={{ color: '#cc3300', marginTop: '60px', marginBottom: '20px', textDecorationColor: '#cc3300' }}>
            Um pouco do nosso trabalho...
          </h2>
          <p style={{ marginBottom: '30px', fontSize: '18px' }}>
            Confira abaixo um pouco do nosso trabalho com turmas de graduação, cursos, ensinos fundamental e médio e muito mais...
          </p>
          <MediaCarousel max_height={max_height}/>
          <h2 style={{ color: '#cc3300', marginTop: '30px', marginBottom: '20px', textDecorationColor: '#cc3300' }}>
            Nossa galeria:
          </h2>
          <p style={{ marginBottom: '30px', fontSize: '18px' }}>
            Clique no botão abaixo e acesse nossa galeria para conhecer mais sobre o nosso trabalho.
          </p>
          <button 
            onClick={() => setIsOpen(true)}
            style={{
                backgroundColor: '#cc3300',
                color: 'white',
                borderRadius: '16px',
                padding: '6px 12px',
                border: '2px solid #cc3300',
                width: '100%'
                
            }}
          >
            <FontAwesomeIcon icon={faCamera} style={{ color: 'white', marginRight:'10px' }} />
            <b>ABRIR GALERIA</b></button>
          <GalleryModal isOpen={isOpen} handleClose={() => setIsOpen(false)} />
        </>
        
    );
};

export default Gallery;