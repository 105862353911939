import ufu from '../logos/ufu.jpeg';
import gabarito from '../logos/gabarito.jpeg';
import una from '../logos/una.jpeg';
import uniube from '../logos/uniube.jpeg';
import maispositivo from '../logos/maispositivo.jpeg';
import objetivo from '../logos/objetivo.jpeg';
import institutomix from '../logos/institutomix.jpeg';
import puc from '../logos/puc.jpeg';
import colegiotiradentes from '../logos/colegiotiradentes.jpeg';
import unifran from '../logos/unifran.jpeg';
import cruzeirodosul from '../logos/cruzeirodosul.jpeg';
import grautecnico from '../logos/grautecnico.jpeg';
import colegioaplicacao from '../logos/colegioaplicacao.jpeg';
import colegiovisao from '../logos/colegiovisao.jpeg';
import univar from '../logos/univar.jpeg';
import colegioprofissional from '../logos/colegioprofissional.jpeg';
import uftm from '../logos/uftm.jpeg';

const cards = [
    {
      title: "UFU",
      image: ufu,
    },
    {
      title: "Colégio Gabarito",
      image: gabarito,
    },
    {
      title: "UNA",
      image: una,
    },
    {
      title: "UNIUBE",
      image: uniube,
    },
    {
      title: "Colégio Mais Positivo",
      image: maispositivo,
    },
    {
      title: "Colégio Objetivo",
      image: objetivo,
    },
    {
      title: "Instituto Mix",
      image: institutomix,
    },
    {
      title: "PUC Minas",
      image: puc,
    },
    {
      title: "Colégio Tiradentes Militar",
      image: colegiotiradentes,
    },
    {
      title: "UNIFRAN",
      image: unifran, //Fim da seção 1
    },
    {
      title: "Cruzeiro do Sul - Uberlândia/MG",
      image: cruzeirodosul,
    },
    {
      title: "Grau Técnico",
      image: grautecnico,
    },
    {
      title: "Colégio Aplicação Ulbra - Itumbiara/GO",
      image: colegioaplicacao,
    },
    {
      title: "Colégio Visão - Itumbiara/GO",
      image: colegiovisao,
    },
    {
      title: "Colégio Profissional - Uberlândia/MG",
      image: colegioprofissional,
    },
    {
      title: "UNIVAR",
      image: univar,
    },
    {
      title: "UFTM", // última inserida
      image: uftm,
    },
    
  ];

export default cards;