import Carousel from 'react-bootstrap/Carousel';
import React, { useState, useEffect } from 'react';

function MainPage({ max_height, max_width}) {

  const [imagePaths, setImagePaths] = useState([]);

  useEffect(() => {
      async function fetchImages() {
        fetch('https://flashfotosformaturas.com.br/api/listar_imagens.php?pasta=principal').then(response => 
            response.json()
        ).then(data => {
            const images = data;
            setImagePaths(images)
          })
      }
      fetchImages();
  }, []);

  // Função para importar as imagens
  const importImages = (imageNames) => {
      return imageNames.map(imageName => {
          // Importa a imagem usando require
          const image = imageName;

          // Retorna um elemento <img> com a imagem importada
          return <img className="d-block mx-auto my-auto" key={imageName} src={image} alt={imageName} style={{ maxWidth: '100vw', maxHeight: max_height }}/>;
      });
  };

  return (
    <Carousel interval={3000} fade style={{ height: (window.innerWidth/window.innerHeight) <= 1 ? 'auto' : max_height, backgroundColor: 'rgba(0, 0, 0, 0.9)', borderRadius: '8px', overflow: 'hidden'}}>
            {importImages(imagePaths).map((image, index) => (
                <Carousel.Item key={index}>
                    {image}
                </Carousel.Item>
            ))}
        </Carousel>
  );
}

export default MainPage;