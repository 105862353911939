import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css'; // Importando estilos

const CardCarousel = ({ cards }) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3, // Número de itens a rolar por vez
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  return (
    <Carousel
        arrows={false}
        swipeable={true}
        draggable={true}
        showDots={false}
        responsive={responsive}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={3000}
        keyBoardControl={true}
        customTransition="transform 300ms ease-in-out"
        transitionDuration={300}
        containerClass="carousel-container"
        removeArrowOnDeviceType={['tablet', 'mobile']}
        deviceType="desktop"
        dotListClass="custom-dot-list-style"
    >
      {cards.map((card, index) => (
        <div key={index} style={{ padding: '10px' }} className="carousel-card">
            <div className="image-container">
                <img className="rounded-image" src={card.image} alt={card.title} />
            </div>
            <div className="title-container">
                <h2>{card.title}</h2>
            </div>
        </div>
    ))}
    </Carousel>
  );
};

export default CardCarousel;


