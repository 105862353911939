import React, { useState, useEffect, useRef } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import { BsX, BsList } from 'react-icons/bs'; 
import { ReactComponent as Logo } from '../logo.svg';


function MainNavbar({ onNavbarHeightChange }) {
    const [navbarHeight, setNavbarHeight] = useState(0);
    const [isOpen, setIsOpen] = useState(false); 
    const navbarRef = useRef(null);

    useEffect(() => {
        setNavbarHeight(navbarRef.current.clientHeight);
        onNavbarHeightChange(navbarRef.current.clientHeight);
      }, [onNavbarHeightChange]);

    const [activeItem, setActiveItem] = useState('home');

    const handleItemClick = (itemName) => {
        setActiveItem(itemName);
        setIsOpen(!isOpen);
    };

    useEffect(() => {
      const handleScroll = () => {
        const sections = document.querySelectorAll('.section');
        let currentSection = null;
  
        sections.forEach(section => {
          const sectionTop = section.getBoundingClientRect().top + window.scrollY;
          const sectionHeight = section.clientHeight;
          if (window.scrollY >= sectionTop - navbarHeight - 10 && window.scrollY < sectionTop + sectionHeight) {
            currentSection = section.getAttribute('name');
          }
        });
        setActiveItem(currentSection);
      };
  
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, [navbarHeight]);

    useEffect(() => {
      const stateObj = { section: activeItem };
      window.history.replaceState(stateObj, '', window.location.pathname);
    }, [activeItem]);

  return (
    <Container className="rounded-lg">
      <Navbar collapseOnSelect expand="lg" data-bs-theme="dark" fixed='top' ref={navbarRef}  style={{ borderRadius: '20px', margin: '15px', backgroundColor: 'rgba(0, 0, 0, 0.9)'}}>
        <Container>
          <Navbar.Brand href="/">
            <Logo
                width="208"
                height="75"
                className="d-inline-block align-top"
                alt="Logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" className="custom-toggle" onClick={() => setIsOpen(!isOpen)} style={{ outline: 'none' }}>
              {isOpen ? <BsX className="icon" /> : <BsList className="icon" />} {/* Adiciona a classe "icon" para estilizar o ícone separadamente */}
          </Navbar.Toggle>
          <Navbar.Collapse className="justify-content-end" isOpen={isOpen}>
            <Nav className="ml-auto">
                <Nav.Link 
                    href="#home" 
                    className={`nav-link-custom ${activeItem === 'home' ? 'active' : ''}`} 
                    onClick={() => handleItemClick('home')}
                    style={{
                        borderBottom: activeItem === 'home' && window.innerWidth >= 1000 ? '2px solid #cc3300' : 'none',
                        color: activeItem === 'home' ? '#cc3300' : 'white',
                        marginLeft: '10px',
                        fontWeight: activeItem === 'home' ? 'bold': 'normal'
                      }}
                    >Início</Nav.Link>
                <Nav.Link 
                    href="#gallery" 
                    className={`nav-link-custom ${activeItem === 'gallery' ? 'active' : ''}`} 
                    onClick={() => handleItemClick('gallery')}
                    style={{
                        borderBottom: activeItem === 'gallery' && window.innerWidth >= 1000 ? '2px solid #cc3300' : 'none',
                        color: activeItem === 'gallery' ? '#cc3300' : 'white',
                        marginLeft: '10px',
                        fontWeight: activeItem === 'gallery' ? 'bold': 'normal'
                      }}
                    >Nosso Trabalho</Nav.Link>
                {/*}
                  <Nav.Link 
                    href="#statements" 
                    className={`nav-link-custom ${activeItem === 'statements' ? 'active' : ''}`} 
                    onClick={() => handleItemClick('statements')}
                    style={{
                        borderBottom: activeItem === 'statements' && window.innerWidth >= 1000 ? '2px solid #cc3300' : 'none',
                        color: activeItem === 'statements' ? '#cc3300' : 'white',
                        marginLeft: '10px',
                        fontWeight: activeItem === 'statements' ? 'bold': 'normal'
                      }}
                    >Depoimentos</Nav.Link> */}
                <Nav.Link 
                    href="#about" 
                    className={`nav-link-custom ${activeItem === 'about' ? 'active' : ''}`} 
                    onClick={() => handleItemClick('about')}
                    style={{
                        borderBottom: activeItem === 'about' && window.innerWidth >= 1000 ? '2px solid #cc3300' : 'none',
                        color: activeItem === 'about' ? '#cc3300' : 'white',
                        marginLeft: '10px',
                        fontWeight: activeItem === 'about' ? 'bold': 'normal'
                      }}
                    >Sobre</Nav.Link>
                <Nav.Link 
                    href="https://formandos.flashfotosformaturas.com.br" 
                    style={{
                        backgroundColor: '#cc3300',
                        color: 'white',
                        borderRadius: '16px',
                        padding: '6px 12px',
                        border: '2px solid #cc3300',
                        marginLeft: '10px',
                        marginBottom: window.innerWidth >= 1000 ? '0px' : '10px'
                        
                    }}
                  >Área do Formando</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Container>
  );
}

export default MainNavbar;
