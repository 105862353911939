import React from 'react';
import { Fab } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const WhatsAppButton = () => {
  const handleWhatsAppClick = () => {
    // Adicione o link do WhatsApp aqui
    window.location.href = 'https://api.whatsapp.com/send?phone=5564996627336&text=Ol%C3%A1.%20';
  };

  return (
    <Fab
      aria-label="whatsapp"
      size="large"
      sx={{
        position: 'fixed',
        bottom: 32,
        right: 32,
        width: '64px',
        height: '64px',
        backgroundColor: '#25D366', // Verde do WhatsApp
        '&:hover': {
          backgroundColor: '#128C7E', // Verde mais escuro do WhatsApp no hover
        },
      }}
      onClick={handleWhatsAppClick}
    >
      <WhatsAppIcon fontSize="large" sx={{ color: 'white' }}/>
    </Fab>
  );
};

export default WhatsAppButton;