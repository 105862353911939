import React, { useState, useEffect } from 'react';
import { Modal, Button, Grid, Card, CardActionArea, CardMedia, Typography, IconButton, Paper, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FsLightbox from 'fslightbox-react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const GalleryModal = ({ isOpen, handleClose }) => {
  const [folders, setFolders] = useState([]);
  const [currentFolder, setCurrentFolder] = useState(null);
  const [images, setImages] = useState([]);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [lightboxIndex, setLightboxIndex] = useState(0);


  useEffect(() => {
    fetchFolders();
  }, []);

  const fetchFolders = async () => {
    try {
        const response = await fetch("https://flashfotosformaturas.com.br/api/listar_pastas.php");
        const data = await response.json();
        setFolders(data);
        } catch (error) {
        console.error('Error fetching folders:', error);
        }
    };

  const fetchImages = async (folderName) => {
    try {
      const response = await fetch(`https://flashfotosformaturas.com.br/api/listar_imagens.php?pasta=galeria/${encodeURIComponent(folderName)}`);
      const data = await response.json();
      data.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
      setImages(data);
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  };

  const handleOpenFolder = (folder) => {
    setCurrentFolder(folder);
    fetchImages(folder);
  };

  const handleCloseFolder = () => {
    setCurrentFolder(null);
    setImages([]);
  };

  const handleOpenLightbox = (index) => {
    setLightboxOpen(!lightboxOpen);
    setLightboxIndex(index);
  };

  const handleCloseInternal = () => {
    setCurrentFolder(null);
    handleClose();

  };


  return (
    <Modal
    open={isOpen}
    onClose={handleClose}
    aria-labelledby="gallery-modal-title"
    aria-describedby="gallery-modal-description"
    sx={{
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start'
    }}
    >
    <Paper
        sx={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(255, 255, 255)',
          outline: 'none',
          flex:'none',
          margin: 'auto',
          overflow: 'auto',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
      <div>
        <Box sx={{ position: 'fixed', zIndex:999 }}>
            <IconButton onClick={handleCloseInternal} sx={{ position: 'fixed', top: 10, right: 10, backgroundColor:'white'  }}>
            <CloseIcon sx={{color:'black'}} />
            </IconButton>
            {currentFolder ? (
                <Button startIcon={<ArrowBackIcon />} 
                onClick={handleCloseFolder} 
                sx={{ position: 'fixed', top: 10, left: 10, color: 'black', backgroundColor: 'white',
                    '& .MuiSvgIcon-root': {
                      fill: 'black', // Cor da seta
                    }
                    ,}}>Voltar</Button>) : (<></>)}
        </Box>  
        <Box>
            {currentFolder ? (
            <Grid container spacing={2} sx={{marginTop: '35px'}}>
                {images.map((imageUrl, index) => (
                <Grid item xs={6} sm={4} md={3} key={index}>
                    <Card onClick={() => handleOpenLightbox(index)} sx={{ margin: '3%' }}>
                    <CardActionArea>
                        <CardMedia
                        component="img"
                        src={imageUrl}
                        alt={`Image ${index + 1}`}
                        />
                    </CardActionArea>
                    </Card>
                </Grid>
                ))}
            </Grid>
            ) : (
            <Grid container spacing={2} sx={{marginTop: '35px'}}>
                {folders.map((folder, index) => (
                <Grid item xs={6} sm={4} md={3} key={index}>
                    <Card onClick={() => handleOpenFolder(folder)} sx={{ margin: '2%', marginTop: '5%'}}>
                    <CardActionArea>
                        <CardMedia
                        component="img"
                        src={`https://flashfotosformaturas.com.br/images/galeria/${encodeURIComponent(folder)}/capa.jpg`}
                        alt={folder}
                        
                        />
                        <Typography variant="subtitle1" sx={{ textAlign: 'center', marginTop: 1 }}>
                        {folder}
                        </Typography>
                    </CardActionArea>
                    </Card>
                </Grid>
                ))}
            </Grid>
            )}
        </Box>
        <FsLightbox toggler={lightboxOpen} sources={images} types={[...new Array(images.length).fill('image')]} />
      </div>
      </Paper>
    </Modal>
  );
};

export default GalleryModal;

